import { FC, useEffect, useMemo, useState } from 'react';
import Dropdown from 'components/Dropdown/Dropdown';
import Caret from 'components/Icons/Caret';

interface DropdownPickerProps {
  content: JSX.Element;
	className?: string;
	dropdownContentWrapperClassName?: string;
}

const DropdownPicker: FC<DropdownPickerProps> = ({ content, children, className, dropdownContentWrapperClassName }) => {
  const [isActive, setIsActive] = useState(false);

  const caretClassList = useMemo(() => {
    const rotateClass = isActive ? '-rotate-180' : 'rotate-0';

    return 'transition-transform transform duration-500 ' + rotateClass;
  }, [isActive]);

  return (
    <Dropdown
      content={content}
      title={children?.toString()}
      onToggle={setIsActive}
			className={`${className}`}
      contentWrapperClassName={`border border-brand-grey350 ${dropdownContentWrapperClassName}`}
      contentWrapperParentClassName="max-h-[262px]">
      <div className="flex h-[48px] w-full cursor-pointer select-none items-center justify-between rounded-[6px] border border-brand-grey350 bg-white px-[16px] text-[14px]">
        <span className="font-medium text-brand-grey450">{children}</span>
        <span className={caretClassList}>
          <Caret />
        </span>
      </div>
    </Dropdown>
  );
};

export default DropdownPicker;
