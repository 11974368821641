import Image from "next/legacy/image";
import Link from 'next/link';
import DropdownPicker from 'components/DropdownPicker/DropdownPicker';

interface LocationPickerStore {
  name: string;
  path: string;
  googleReviewUrl: string;
}

interface LocationPickerState {
  code: string;
  name: string;
  storeList: LocationPickerStore[];
  path: string;
}

export interface LocationPickerCountry {
  code: string;
  name: string;
  stateList: LocationPickerState[];
}

interface LocationPickerProps {
  countryList: LocationPickerCountry[];
}

const LocationList = ({ countryList }: LocationPickerProps) => (
  <div>
    {countryList.map((country) => (
      <LocationCountry
        code={country.code}
        name={country.name}
        stateList={country.stateList}
        key={country.code}
      />
    ))}
  </div>
);

const LocationCountry = ({ code, name, stateList }: LocationPickerCountry) => (
  <div>
    <div className="flex items-center py-[6px]">
      <Image
        width={22}
        height={15}
        src={`/images/flags/${code}.png`}
        alt={code}
      />
      <span className="ml-[8px] font-medium text-brand-black100">
        {name} Locations
      </span>
    </div>
    {stateList.map((state) => (
      <LocationState
        code={state.code}
        name={state.name}
        storeList={state.storeList}
        key={state.code}
        path={state.path}
      />
    ))}
  </div>
);

const LocationState = ({ code, storeList, path }: LocationPickerState) => (
  <div className="flex flex-col pl-[32px]">
    <Link
      href={path}
      className="py-[6px] font-medium text-brand-black100 transition-colors sm:hover:text-brand-orange">

      {code}

    </Link>
    <div className="flex flex-col overflow-hidden pl-[16px]">
      {storeList.map((store) => (
        <LocationStore
          name={store.name}
          path={store.path}
          googleReviewUrl={store.googleReviewUrl}
          key={store.name + '_' + store.path}
        />
      ))}
    </div>
  </div>
);

const LocationStore = ({ name, path }: LocationPickerStore) => (
  (<Link
    prefetch={false}
    href={path}
    target="_blank"
    className="py-[6px] text-[14px] font-normal text-brand-black25 transition-colors duration-200 hover:text-brand-orange">

    {name}

  </Link>)
);

const LocationPicker = ({ countryList }: LocationPickerProps) => {
  
  return (
  <DropdownPicker dropdownContentWrapperClassName="location-picker-popup" content={<LocationList countryList={countryList} />}>
    Select Location
  </DropdownPicker>
)
};

export default LocationPicker;
