import { useEffect, useState } from 'react';

const useDeviceSupportsTouch = () => {
  const [isDeviceSupportsTouch, setIsDeviceSupportsTouch] = useState(false);

  useEffect(() => {
    if (!window) return;

    const eventHandler = () => {
      setIsDeviceSupportsTouch(
        'ontouchstart' in window ||
          navigator.maxTouchPoints > 0 ||
          //@ts-ignore
          navigator.msMaxTouchPoints > 0
      );
    };

    eventHandler();

    window.addEventListener('orientationchange', eventHandler);
    window.addEventListener('resize', eventHandler);

    return () => {
      window.removeEventListener('orientationchange', eventHandler);
      window.removeEventListener('resize', eventHandler);
    };
  });

  return [isDeviceSupportsTouch];
};

export default useDeviceSupportsTouch;
