import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import useDeviceSupportsTouch from 'hooks/useDeviceSupportsTouch';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useWindowSize from 'hooks/useWindowSize';
import DropdownContent from './DropdownContent';

const MOBILE_VIEWPORT_WIDTH = 1023;

interface DropdownProps {
  className?: string;
  content: JSX.Element | ReactNode;
  contentWrapperClassName?: string;
  contentWrapperParentClassName?: string;
  title?: string;
  buttonName?: string;
  // eslint-disable-next-line no-unused-vars
  onToggle?: (visibilityStatus: boolean) => void;
  interactionType?: 'CLICK' | 'MOUSEOVER';
  disableMobileDrawer?: boolean;
  disableScrollLockOnMobile?: boolean;
  wrapperClassName?: string;
}

const Dropdown: FC<DropdownProps> = ({
  className,
  content,
  children,
  contentWrapperClassName = '',
  contentWrapperParentClassName = '',
  title,
  buttonName,
  onToggle,
  interactionType = 'CLICK',
  disableMobileDrawer,
  disableScrollLockOnMobile,
  wrapperClassName = ''
}) => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const [isDeviceSupportsTouch] = useDeviceSupportsTouch();

  const { width } = useWindowSize();

  const toggleContentVisibility = () => setIsContentVisible(!isContentVisible);
  const contentElement = useRef<HTMLDivElement>(null);

  const isMobile = useMemo(
    () => !disableMobileDrawer && width <= MOBILE_VIEWPORT_WIDTH,
    [width, disableMobileDrawer]
  );

  useOnClickOutside(contentElement, () => {
    if (!isContentVisible || isMobile) return;

    toggleContentVisibility();
  });

  const onMouseEnter = () => {
    if (interactionType !== 'MOUSEOVER' || isDeviceSupportsTouch) return;

    setIsContentVisible(true);
  };

  const onMouseLeave = () => {
    if (interactionType !== 'MOUSEOVER' || isDeviceSupportsTouch) return;
    setIsContentVisible(false);
  };

  const clickHandler = () => {
    if (
      interactionType === 'CLICK' ||
      isDeviceSupportsTouch ||
      (interactionType === 'MOUSEOVER' && !isContentVisible)
    ) {
      toggleContentVisibility();
    }
  };

  useEffect(() => {
    if (onToggle) onToggle(isContentVisible);
  }, [isContentVisible, onToggle]);

  return (
    <div
      className={`relative ${className || ''}`}
      ref={contentElement}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <div className={wrapperClassName} onClick={clickHandler}>{children}</div>
      <DropdownContent
        onToggle={toggleContentVisibility}
        isVisible={isContentVisible}
        contentWrapperClassName={contentWrapperClassName}
        contentWrapperParentClassName={contentWrapperParentClassName}
        isMobile={isMobile}
        disableScrollLockOnMobile={disableScrollLockOnMobile}
        title={title}
        buttonName={buttonName}>
					<div onClick={() => toggleContentVisibility() }>
        		{content}
					</div>
      </DropdownContent>
    </div>
  );
};

export default Dropdown;
