import { FC, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';
import CrossMark from 'components/Icons/CrossMark';
import useOnClickOutside from 'hooks/useOnClickOutside';
import OverlayPortal from './OverlayPortal';

interface FullscreenOverlayProps {
  isVisible: boolean;
  // eslint-disable-next-line no-unused-vars
  onToggleVisibility?: (visibility: boolean) => void;
  className?: string;
  title?: string;
  buttonName?: string;
  direction?: 'left' | 'bottom';
  contentWrapperClassName?: string;
  disableScrollLock?: boolean;
}

const FullscreenOverlay: FC<FullscreenOverlayProps> = ({
  isVisible,
  children,
  className,
  onToggleVisibility,
  title,
  buttonName,
  direction = 'bottom',
  contentWrapperClassName = '',
  disableScrollLock = false
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (disableScrollLock) return;

    const rootEl = document.querySelector('html');

    if (!isVisible)
      return rootEl?.removeAttribute('data-fullscreen-overlay-visible');

    rootEl?.setAttribute('data-fullscreen-overlay-visible', 'true');

    return () => {
      if (!disableScrollLock) {
        rootEl?.removeAttribute('data-fullscreen-overlay-visible');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const contentElement = useRef<HTMLDivElement>(null);

  const hideOverlay = () => {
    if (!isVisible || !onToggleVisibility) return;
    onToggleVisibility(false);
  };

  const onClickOutside = (event: MouseEvent | TouchEvent) => {
    const overlayPortal = document.querySelector('#overlay-portal');

    const target = event.target as HTMLDivElement;

    const isEventFromAnotherOverlay =
      target &&
      overlayPortal?.contains(target) &&
      !overlayRef.current?.contains(target);

    if (isEventFromAnotherOverlay) return;

    hideOverlay();
  };

  useOnClickOutside(contentElement, onClickOutside);

  return (
    <OverlayPortal>
      <div ref={overlayRef}>
        <Transition
          data-fullscreen-overlay
          appear={true}
          show={isVisible}
          className="fixed left-0 top-0 z-[99999] h-full w-full">
          <Transition.Child
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="overlay-backdrop">
            <div className="overlay-backdrop absolute left-0 top-0 h-full w-full bg-brand-black100 opacity-75" />
          </Transition.Child>
          <Transition.Child
            enter="transition ease-in-out duration-500 transform"
            enterFrom={
              direction === 'left'
                ? 'opacity-0 -translate-x-full'
                : 'translate-y-full'
            }
            enterTo={
              direction === 'left'
                ? 'translate-x-0 opacity-100'
                : 'translate-y-0'
            }
            leave="transition ease-in-out duration-500 transform"
            leaveFrom={
              direction === 'left'
                ? 'translate-x-0 opacity-100'
                : 'translate-y-0'
            }
            leaveTo={
              direction === 'left'
                ? '-translate-x-full opacity-0'
                : 'translate-y-full'
            }
            className={`absolute bottom-0 left-0 flex h-full w-full flex-grow-0 items-end pt-[20vh] ${className}`}>
            <div
              className={`flex max-h-full w-full flex-col bg-white p-[16px] ${contentWrapperClassName}`}
              ref={contentElement}>
              {title && (
                <div className="mb-[20px] flex items-center justify-between text-[18px] font-medium text-brand-black100 px-2">
                  <p>{title}</p>
                  <div onClick={hideOverlay} className="cursor-pointer">
                    <CrossMark />
                  </div>
                </div>
              )}
              <div className="max-h-full flex-auto overflow-auto">
                {children}
              </div>
              {buttonName && (
                <div className="flex justify-center bg-black rounded-md cursor-pointer" onClick={hideOverlay}>
                  <button className="py-3">
                      <span className="text-white"> { buttonName } </span>
                  </button>
                </div>
              )}
            </div>
          </Transition.Child>
        </Transition>
      </div>
    </OverlayPortal>
  );
};

export default FullscreenOverlay;
