import { cloneElement, FC } from 'react';
import { ReactElement } from 'react';
import { Transition } from '@headlessui/react';
import FullscreenOverlay from 'components/Others/FullscreenOverlay';

interface DropdownContentProps {
  onToggle?: () => void;
  contentWrapperClassName?: string;
  contentWrapperParentClassName?: string;
  isVisible: boolean;
  title?: string;
  buttonName?: string;
  isMobile?: boolean;
  disableScrollLockOnMobile?: boolean;
}

const MobileDropdownContent: FC<DropdownContentProps> = ({
  children,
  onToggle,
  isVisible,
  title,
  buttonName,
  disableScrollLockOnMobile,
  contentWrapperClassName
}) => {
  return (
    <FullscreenOverlay
      isVisible={isVisible}
      title={title}
      buttonName={buttonName}
      onToggleVisibility={onToggle}
      contentWrapperClassName={contentWrapperClassName}
      disableScrollLock={disableScrollLockOnMobile}
    >
      {children}
    </FullscreenOverlay>
  );
};

const DefaultDropdownContent: FC<DropdownContentProps> = ({
  children,
  contentWrapperClassName,
  contentWrapperParentClassName,
  isVisible
}) => {
  return (
    <Transition
      enter="transition ease-in-out duration-500 transform"
      enterFrom="translate-y-[25px] opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transition ease-in-out duration-300 transform"
      leaveFrom="translate-y-0 opacity-100"
      leaveTo="translate-y-[15px] opacity-0"
      className={`absolute left-0 top-full mt-[1px] min-w-full rounded-[6px] border-brand-grey350 bg-white p-[16px] ${contentWrapperClassName}`}
      appear={true}
      show={isVisible}>
      <div className={`overflow-auto ${contentWrapperParentClassName || ''}`}>
        {children}
      </div>
    </Transition>
  );
};

const DropdownContent: FC<DropdownContentProps> = ({
  children,
  onToggle,
  contentWrapperClassName,
  contentWrapperParentClassName,
  isVisible,
  title,
  buttonName,
  isMobile,
  disableScrollLockOnMobile
}) => {
  return !isMobile ? (
    <DefaultDropdownContent
      onToggle={onToggle}
      isVisible={isVisible}
      contentWrapperClassName={contentWrapperClassName}
      contentWrapperParentClassName={contentWrapperParentClassName}>
      {cloneElement(children as ReactElement, { onToggle, isMobile })}
    </DefaultDropdownContent>
  ) : (
    <MobileDropdownContent
      onToggle={onToggle}
      isVisible={isVisible}
      contentWrapperClassName={contentWrapperClassName}
      title={title}
      buttonName={buttonName}
      disableScrollLockOnMobile={disableScrollLockOnMobile}>
      {cloneElement(children as ReactElement, { onToggle, isMobile })}
    </MobileDropdownContent>
  );
};

export default DropdownContent;
